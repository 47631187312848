import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserLibService } from '../../service/user/user-lib.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageLibService } from '../../service/storage/storage-lib.service';
import { GrpcLibService } from '../../service/grpc/grpc-lib.service';
import { GrpcEventLibService } from '../../service/grpc/event/grpc-event-lib.service';
import { of, Subject } from 'rxjs';
import { EventMobile } from '../../libs/proto/mobile_pb';
import { desktopMode } from '../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import { DateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { CalendarView, CalendarEvent } from 'angular-calendar';
import {
  isSameDay,
  isSameMonth,
} from 'date-fns';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';

import { registerLocaleData } from '@angular/common';
import { SidenavComponent } from 'src/app/sidenav/sidenav.component';
import { test_menu_type } from 'src/app/config/beta';
@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.sass']
})
export class EventsComponent implements OnInit {

  @ViewChild('sidenav') sidenavctk: SidenavComponent;

  events$: Promise<EventMobile[]>;
  isEventList = true;
  eventsK: Map<string, EventMobile[]> = new Map<string, EventMobile[]>();

  calendarView: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  calendarDate: Date = new Date();
  calendarSelect: Date = new Date();
  calendarRefresh: Subject<any> = new Subject();
  calendarEvents: CalendarEvent[];
  calendarActiveDayIsOpen: boolean;

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;
  menuType = 0;

  constructor(
    private route: Router,
    private grpcLib: GrpcEventLibService,
    private userLib: UserLibService,
    private translate: TranslateService,
    private storeLib: StorageLibService,
    private dateAdp: DateAdapter<Date>,
    private detector: DeviceLibService,
  ) {
    userLib.setPageLanguage( this.translate );
  }

  ngOnInit(): void {
    this.dateAdp.setLocale(this.userLocale);
    if (this.userLocale == 'fr') registerLocaleData(localeFr);
    if (this.userLocale == 'nl') registerLocaleData(localeNl);
    if (this.userLocale == 'es') registerLocaleData(localeEs);
    if (this.userLocale == 'pt') registerLocaleData(localePt);

    const thise = this;
    this.events$ = this.grpcLib.getEvent({
      Offline: this.storeLib.cache.event || false,
    }).then( es => {
      for (const e of es) {
        const k = e.getEvent().getDate() + '';
        if (!thise.eventsK.has(k)) {
          thise.eventsK.set(k, []);
        }
        thise.eventsK.get(k).push( e );
      }
      return of(es).toPromise();
    })
    .catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        this.userLib.clear();
        this.route.navigateByUrl('/login');
        return;
      }
      // return to complete
      return of([]).toPromise();
    });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });
  }

  get userLocale(): string {
    return this.userLib.Data.lg || 'en';
  }

  open(e: EventMobile){
    this.route.navigate(['/event', e.getEvent().getId()]);
  }

  get dateFormat(): string {
    return this.userLib.dateFormat;
  }

  isPast(d: number) {
    return +moment().format('YYYYMMDD') > d;
  }
  eventChange(type: string) {
    switch (type) {
      case 'event.list':
        this.isEventList = true;
        break;
      case 'event.calendar':
        this.isEventList = false;
        break;
      case 'event.today':
        this.calendarDate = new Date();
        break;
      default:
        break;
    }
  }
  calendarDayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.calendarDate)) {
      if (
        (isSameDay(this.calendarDate, date) && this.calendarActiveDayIsOpen === true) ||
        events.length === 0
      ) {
        this.calendarActiveDayIsOpen = false;
      } else {
        this.calendarActiveDayIsOpen = true;
      }
      this.calendarSelect = date;
      if (this.countEvent(date) > 0) {
        this.route.navigate(['/event', moment(date).format('YYYYMMDD')]);
      }
    }
  }

  countEvent(d: Date): number {
    return this.eventsK.get( moment(d).format('YYYYMMDD') )?.length;
  }
  selectedEvent(d: string) {
    return  moment(this.calendarSelect).format('YYYYMMDD') === d;
  }
  getMonth(d: number) {
    return moment(d + '', 'YYYYMMDD').locale(this.userLocale).format('MMM');
  }
  prevMonth() {
    this.calendarDate = moment(this.calendarDate).subtract(1, 'month').toDate();
  }
  nextMonth() {
    this.calendarDate = moment(this.calendarDate).add(1, 'month').toDate();
  }
  statusEventIcon(att: number) {
    switch (att){
    case 1:
      return 'check_circle_outline';
    case 2:
      return 'highlight_off';
    default:
      return 'help_outline';
    }
  }
  statusEventClass(att: number) {
    switch (att){
    case 1:
      return 'attend';
    case 2:
      return 'not-attend';
    default:
      return '';
    }
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
       if (test_menu_type) {
        return parseInt(test_menu_type);
    }
     // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer()?.getMobilemenutype();
  }
}
