import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UserLibService } from 'src/app/service/user/user-lib.service';
import { StorageLibService } from 'src/app/service/storage/storage-lib.service';
import { Router } from '@angular/router';
import { menu_type_def } from './menu_type_def';
import { MenuName } from 'src/app/libs/proto/commUnity_pb';


@Component({
  selector: 'app-menu-type-side',
  templateUrl: 'menu_type_side.html',
  styleUrls: ['./menu_type_side.sass'],
  encapsulation: ViewEncapsulation.None,
})
export class menu_type_side extends menu_type_def { 

  @Input() menu: MenuName;

  constructor(
    private _userLib: UserLibService,    
    private _storageLib: StorageLibService,
    private _route: Router,
  ){
    super(_userLib, _storageLib, _route);
    
  }
}
