import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserLibService } from '../../service/user/user-lib.service';
import { StorageLibService } from '../../service/storage/storage-lib.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { communityConfig } from '../../config/app';
import { communityVersion, communityDate } from '../../config/version';
import { GrpcOrderLibService } from '../../service/grpc/order/grpc-order-lib.service';
import { OrderHistoryComponent } from '../order/order-history/order-history.component';
import { GrpcMenuLibService } from '../../service/grpc/menu/grpc-menu-lib.service';
import { Router } from '@angular/router';
import { NewsPathConverter } from '../../service/conversion/news/news-path-converter';
import { LinksPathConverter } from 'src/app/service/conversion/links/links-path-converter';

import { StreamPathConverter } from 'src/app/service/conversion/stream/stream-path-converter';
import { SidenavComponent } from 'src/app/sidenav/sidenav.component';
import { MenuName } from 'src/app/libs/proto/commUnity_pb';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.sass']
})
export class ToolbarComponent implements OnInit {

  @Input() page: 'home' |
                 'disclaimer' |
                 'news' | 'new' |
                 'benefits' | 'benefit' |
                 'links' | 'link' |
                 'streams' | 'stream' |
                 'events' | 'event' |
                 'shop' | 'product' |
                 'product-confirm' | 'cart' | 'checkout' | 'payment' |
                 'account' |
                 'about' |
                 'cpsurls' |
                 'albums' | 'album' |
                 'places' | 'place' |
                 'forum' |
                 'members' | 'member-page' | 'member-business-card-page' |
                 'guest-reservation' | 'guest-reservations' | 'guest-precheckin' |
                 'guest-satisfaction' |
                 'guest-bill' |
                 'leaderboard' |
                 'golf_classes' | 'golf_class' |
                 'order-history' |
                 'competitions' |  'competition' |
                 'documents'|
                 'menus' |  'menu' | 'menu-confirm' | 'meal' | 'meal-checkout' | 'meal-payment' |
                 'search' = 'home';
  @ViewChild('titleContainer') titleContainer: ElementRef;
  @ViewChild('titleFront') titleFront: ElementRef;
  @ViewChild('titleBack') titleBack: ElementRef;

  private mtitle?: string;
  private atitle?: string;

  @Input() show_burger_menu: boolean;
  @Input() sidenav: SidenavComponent;
  @Input() ml: string;
  @Input() mr: string;
  @Input() data: string;
  @Input() noCart: string;
  @Input() source: string;
  @Input() pageTitle: string;
  @Input() noMeal: string;
  @Input() noBack: string;
  @Output() closePayment = new EventEmitter();
  @Output() eventsType = new EventEmitter();
  @Output() evtsortgolf = new EventEmitter();
  @Output() evtaddprecheckin = new EventEmitter();

  hasUnpaid = false;
  hasOrder = false;
  emptyBadge: any;
  isEventList = true;
  isTitleOverflow: boolean = false;

  scrollSpeed: number = 100;
  sortgolf = 1;

  constructor(
    private translate: TranslateService,
    private userLib: UserLibService,
    private storeLib: StorageLibService,
    private snackbar: MatSnackBar,
    private grpcLib: GrpcOrderLibService,
    private route: Router,
    private grpcMenuLib: GrpcMenuLibService,
    private newsLib: NewsPathConverter,
    private linksLib: LinksPathConverter,
    private streamLib: StreamPathConverter,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {
    userLib.setPageLanguage( this.translate );
  }

  static getMenuItem(page: string, newsindex: string, streamindex: string, linkindex: string) : MenuName | null {
    switch (page) {
      case 'new':
      case 'news':
        switch (newsindex) {
          case '2':
              return MenuName.MENUNEWS2;
          case '3':
              return MenuName.MENUNEWS3;
          case '4':
              return MenuName.MENUNEWS4;
          case '5':
              return MenuName.MENUNEWS5;
          case '6':
              return MenuName.MENUNEWS6;
          case '7':
              return MenuName.MENUNEWS7;
          case '8':
              return MenuName.MENUNEWS8;
          case '9':
              return MenuName.MENUNEWS9;
          default:
            return MenuName.MENUNEWS;
          }
        case 'benefit':
        case 'benefits':
              return MenuName.MENUBENEFIT
        case 'link':
        case 'links':
          switch (linkindex) {
            case '2':
              return MenuName.MENULINK2
            case '3':
              return MenuName.MENULINK3
            case '4':
              return MenuName.MENULINK4
            default:
              return MenuName.MENULINK
            }
        case 'stream':
        case 'streams':
          switch (streamindex) {
            case '2':
                return MenuName.MENUSTREAM2;
            case '3':
                return MenuName.MENUSTREAM3;
            case '4':
                return MenuName.MENUSTREAM4;
            default:
              return MenuName.MENUSTREAM
            }
        case 'event':
        case 'events':
          return MenuName.MENUEVENT
        case 'shop':
        case 'product':
        case 'product-confirm':
        case 'cart':
        case 'checkout':
        case 'payment':
          return MenuName.MENUSHOP
        case 'about':
          return MenuName.MENUSHOPPROFILE;
        case 'cpsurls':
          return MenuName.MENUCPSLINK;
        case 'album':
        case 'albums':
          return MenuName.MENUALBUM
        case 'place':
        case 'places':
          return MenuName.MENUPLACE
        case 'forum':
          return MenuName.MENUFORUM
        case 'members':
        case 'member-page':
        case 'member-business-card-page':
          return MenuName.MENUMEMBER
      case 'guest-reservation':
      case 'guest-reservations':
      case 'guest-precheckin':
      case 'guest-satisfaction':
      case 'guest-bill':
        return MenuName.MENUROOMRESERVATION
      case 'leaderboard':
        return MenuName.MENULEADERBOARD
      case 'golf_class':
      case 'golf_classes':
        return MenuName.MENUGOLFCLASS
      case 'competition':
      case 'competitions':
          return MenuName.MENUCOMPETITION
      case 'documents':
        return MenuName.MENUDOCUMENT
      case 'menus':
      case 'menu':
      case 'menu-confirm':
      case 'meal':
      case 'meal-checkout':
      case 'meal-payment':
        return MenuName.MENURESTAURANT
    }
  }

  getMenuAlias() {
    switch (this.page) {
      case 'about':
          return this.userLib.Data.menuAlias?.getAboutus();
      case 'album':
      case 'albums':
        return this.userLib.Data.menuAlias?.getAlbum();
      case 'benefit':
      case 'benefits':
        return this.userLib.Data.menuAlias?.getBenefit();
      case 'competition':
      case 'competitions':
        return this.userLib.Data.menuAlias?.getCompetition();
      case 'event':
      case 'events':
        return this.userLib.Data.menuAlias?.getEvent();
      case 'forum':
        return this.userLib.Data.menuAlias?.getForum();
      case 'leaderboard':
        return this.userLib.Data.menuAlias?.getLeaderboard();
      case 'link':
      case 'links':
        return this.menuLinkTitle;
      case 'stream':
      case 'streams':
        return this.menuStreamTitle;
      case 'new':
      case 'news':
        return this.menuNewsTitle;
      case 'place':
      case 'places':
        return this.userLib.Data.menuAlias?.getPlace();
      case 'golf_class':
      case 'golf_classes':
        return this.userLib.Data.menuAlias?.getGolfclasslink();
      case 'shop':
        return this.userLib.Data.menuAlias?.getShop();
      case 'menus':
        return this.userLib.Data.menuAlias?.getRestaurant();
      case 'guest-reservation':
      case 'guest-reservations':
        return 'guest.reservations-title';
      case 'guest-precheckin':
        return 'guest.precheckin-page';
      case 'guest-satisfaction':
        return 'guest.satisfaction-title';
      case 'guest-bill':
        return 'guest.bill-title';
      case 'documents':
        return 'document.title';
      default:
          return '';
    }
  }
  private get menuLinkTitle() {
    switch (this.linksLib.linksIndex) {
      case '2': return this.userLib.Data.menuAlias?.getLink2();
      case '3': return this.userLib.Data.menuAlias?.getLink3();
      case '4': return this.userLib.Data.menuAlias?.getLink4();
      default : return this.userLib.Data.menuAlias?.getLink();
    }
  }
  private get menuStreamTitle() {
    switch (this.streamLib.StreamIndex) {
      case '2': return this.userLib.Data.menuAlias?.getStream2();
      case '3': return this.userLib.Data.menuAlias?.getStream3();
      case '4': return this.userLib.Data.menuAlias?.getStream4();
      default : return this.userLib.Data.menuAlias?.getStream();
    }
  }
  private get menuNewsTitle() {
    switch (this.newsLib.newsIndex) {
      case '2': return this.userLib.Data.menuAlias?.getNews2();
      case '3': return this.userLib.Data.menuAlias?.getNews3();
      case '4': return this.userLib.Data.menuAlias?.getNews4();
      case '5': return this.userLib.Data.menuAlias?.getNews5();
      case '6': return this.userLib.Data.menuAlias?.getNews6();
      case '7': return this.userLib.Data.menuAlias?.getNews7();
      case '8': return this.userLib.Data.menuAlias?.getNews8();
      case '9': return this.userLib.Data.menuAlias?.getNews9();
      default : return this.userLib.Data.menuAlias?.getNews();
    }
  }
  private callMenuAlias(){
    this.grpcMenuLib.getMenuAlias({
      Offline: true,
    }).then( v => {
      this.userLib.Data.menuAlias = v;
    });
  }
  ngOnInit(): void {
    this.callMenuAlias();
    this.translate.get([
      this.page + '.title',
      'about.title',
    ]).toPromise().then( t => {
      this.mtitle = this.getMenuAlias() || t[this.page + '.title'];
      this.atitle = this.userLib.Data.menuAlias?.getAboutus() || t['about.title'];
    });

    this.check_product_order();
    this.check_meal_order();
  }

  ngAfterViewChecked(): void {
    const titleContainerElem = this.titleContainer?.nativeElement;
    if (titleContainerElem?.offsetWidth < titleContainerElem?.scrollWidth) {
      this.isTitleOverflow = true;

      // Calculate animation duration
      let duration: number = titleContainerElem?.scrollWidth / this.scrollSpeed;

      // Set the speed duration based on the animation duration
      if (this.titleFront?.nativeElement && this.titleBack?.nativeElement) {
        this.renderer.setStyle(this.titleFront?.nativeElement, 'animation-duration', `${duration}s`);
        this.renderer.setStyle(this.titleBack?.nativeElement, 'animation-duration', `${duration}s`);
      }
    } else {
      this.isTitleOverflow = false;
    }
    this.changeDetectorRef.detectChanges();
  }

  private check_meal_order() {
    if (['menu',
        'meal',
        'menu-confirm',
        'menus'].indexOf(this.page) < 0) { return ; }
  }
  private check_product_order() {
    if (['product',
        'shop',
        'product-confirm',
        'cart'].indexOf(this.page) < 0) { return ; }
    if (this.userLib.Data.token?.getProfile().getIsguest()) { return; }

    this.grpcLib.getProductOrders({
      Offline: this.storeLib.cache.cart || false,
    }).then( oo => {
      this.hasOrder = (oo || []).length > 0;
      for (const o of (oo || [])) {
        if (OrderHistoryComponent.canPay(o)) {
           this.storeLib.set('order-badge', 'Y');
           this.hasUnpaid = true;
           break;
        }
      }
    });
  }
  has_table_from_scan() {
    return (this.storeLib.get('table-id') || '') !== '';
  }
  showBack() {    
    return ['new',
            'event',
            'benefit',
            'album',
            'competition',
            'search',
            'product',
            'link',
            'links',
            'stream',
            'streams',
            'product-confirm',
            'checkout',
            'payment',
            'place',
            'places',
            'golf_class',
            'golf_classes',
            'order-history',
            'cart',
            'news',
            'benefits',
            'events',
            'albums',
            'members',
            'member-page',
            'member-business-card-page',
            'shop',
            'account',
            'forum',
            'guest-reservations',
            'guest-reservation',
            'guest-precheckin',
            'guest-satisfaction',
            'guest-bill',
            'leaderboard',
            'competitions',
            'menu',
            'menu-confirm',
            'meal',
            'meal-checkout',
            'documents',
            'menus' + (this.has_table_from_scan() ? 'x' : ''),
            'about'].indexOf(this.page) >= 0;
  }
  private backURLNews() {
    return '/news' + this.newsLib.newsIndex;
  }
  private backURLLinks() {
    return '/links' + this.linksLib.linksIndex;
  }
  private backURLStream() {
    return '/streams' + this.streamLib.StreamIndex;
  }
  backURL() {    
    if (this.page === 'new') { return this.backURLNews(); }
    if (this.page === 'link') { return this.backURLLinks(); }
    if (this.page === 'stream') { return this.backURLStream(); }
    if (this.page === 'member-page') { return '/members'; }
    if (this.page === 'event') { return '/events'; }
    if (this.page === 'golf_class') { return '/'; }
    if (this.page === 'golf_classes') { return '/golf_class'; }
    if (this.page === 'album') { return '/albums'; }
    if (this.page === 'benefit') {return '/benefits'; }
    if (this.page === 'cart' && this.noCart) { return '/order/history'; }
    if (this.page === 'order-history') { return '/shop'; }
    if (this.page === 'competition') { return '/competitions'; }
    if (this.page === 'guest-reservations') { return '/'; }
    if (this.page === 'guest-reservation') { return '/guest/reservations'; }
    if (this.page === 'guest-precheckin') { return '/guest/reservations/' + this.data; }
    if (this.page === 'guest-satisfaction') { return '/guest/reservations/' + this.data; }
    if (this.page === 'guest-bill') { return '/guest/reservations/' + this.data; }
    if (this.page === 'menus') { return '/'; }
    if (this.page === 'member-business-card-page') { return '/account'; }

    if (['menu',
         'meal',
         'meal-checkout'].indexOf(this.page) >= 0) { return '/resto'; }
    if (this.page === 'menu-confirm' && this.data) { return '/menu/' + this.data; }

    if (['product',
         'cart',
         'payment',
         'checkout'].indexOf(this.page) >= 0) { return '/shop'; }
    if (this.page === 'product-confirm' && this.data) { return '/product/' + this.data; }

    if (this.page === 'place' && (this.source || '') !== '') {
      if (this.source === 'about') { return '/about'; }
      return '/' + this.source + '/' + this.data;
    } else if (this.page === 'place') {
      return '/places';
    }
  }

  get title() {
    if (this.page === 'place' && (this.source || '') === 'about') {
      return this.atitle;
    }
    return this.pageTitle || this.mtitle;
  }

  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }

  get foregroundColor() {
    return this.userLib.designToolbarTextColor;
  }

  hasCart(): boolean{
    return ((this.noCart || '') === '') && (+this.cartBadge) &&
            !this.userLib.Data.token?.getProfile().getIsguest() &&
           ['product',
            'shop',
            'product-confirm',
            'cart'].indexOf(this.page) >= 0;
  }
  get cartBadge(): string {
    return this.storeLib.get('cart-badge');
  }
  hasMeal(): boolean{
    return ((this.noMeal || '') === '') && (+this.mealBadge) &&
           ['menu',
            'menus',
            'menu-confirm',
            'meal'].indexOf(this.page) >= 0;
  }
  get mealBadge(): string {
    return this.storeLib.get('table-id') ? this.storeLib.get('meal-badge') : '';
  }
  showUnpaid() {
    return this.hasUnpaid && this.page === 'shop';
  }
  showVersion() {
      this.snackbar.open( 'version ' +
                          communityConfig.version +
                          ' build ' + communityVersion +
                          ' (' + communityDate + ')'
                          , 'X', {
                            duration: 1000
                          });
  }
  showEventCalendarIcon() {
    return this.isEventList;
  }
  showEventListIcon() {
    return !this.isEventList;
  }
  showEventCalendar() {
    this.isEventList = false;
    this.eventsType.emit('event.calendar');
  }
  showEventList() {
    this.isEventList = true;
    this.eventsType.emit('event.list');
  }

  get menuAlias() {
    return this.userLib.Data.menuAlias;
  }
  get meal_unpaid() {
    return this.route.url.indexOf('/meal-unpaid') >= 0;
  }
  topayment() {
    this.closePayment.emit();
  }
  show_search() {
    return (['album',
             'albums',
             'benefit',
             'benefits',
             'shop',
             'product',
             'product-confirm',
             'members',
             'member-page',
             'new',
             'news',
             'place',
             'places'
        ].indexOf(this.page) >= 0);
  }
  show_cart() {
    return (['shop',
             'product',
             'product-confirm',
           ].indexOf(this.page) >= 0);
  }
  show_meal() {
    return (['menus',
             'menu',
             'menu-confirm',
           ].indexOf(this.page) >= 0);
  }
  showMyClass() {
    if (this.userLib.Data.token?.getProfile().getIsguest()) {
      this.userLib.clear();
      this.userLib.Data.signOut = true;
      this.route.navigateByUrl('/login');
      return true;
    }

    this.route.navigateByUrl('/golf_classes');
  }
  sortgolfclass() {
    this.sortgolf *= -1;
    this.evtsortgolf.emit(this.sortgolf);
  }
  guest_precheck_add() {
    this.evtaddprecheckin.emit();
  }
  toggle_sidenav() {
    this.sidenav.toggle();
  }
}

