<app-sidenav page="news" menuType>

<div (scroll)="clearScrollFlag()"
  class="form-list" [ngClass]="{tablet: menuType==2}" *ngIf="news$ | async as news ">
  
  <ng-container *ngIf="!hasCategory">
  <mat-action-list *ngFor="let new of news">
    <mat-card >
      <div (click)="open(new.getId())"  >
        <mat-card-header>
          <div mat-card-avatar class="img"
            [ngStyle]="{'background-image': 'url(' + new.getImage() + ')'}" >
          </div>
          <mat-card-title> {{ new.getTitle() }}</mat-card-title>
          <mat-card-subtitle>{{ new.getShortdescription() }}</mat-card-subtitle>
          <mat-card-subtitle>
            <div class="footer">
              <div class="date">{{ new.getPublishdate() | amParse: 'YYYYMMDD' | amDateFormat: dateFormat}}</div>
              <div *ngIf="new.getYoutube() != ''" (click)="stop($event)">
                <a href="{{new.getYoutube()}}">
                <img class="youtube-logo" src="assets/images/youtube-logo.png">
              </a>
            </div>
          </div>          
          </mat-card-subtitle>
        </mat-card-header>
      </div>
    </mat-card>
  </mat-action-list>
  </ng-container>

  <mat-accordion *ngIf="hasCategory" [multi]="true">

    <mat-expansion-panel
    *ngFor="let category of news | GroupByNewsCategory"
      (afterExpand)="expanded(category.id, 1)"
      (afterCollapse)="expanded(category.id, 0)"
      [expanded]="!isCollapsed(category.id)"
      class="news-page"
      >
      <mat-expansion-panel-header
        class="news-category">
        <mat-panel-title>
          {{ category.label }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <mat-card
        *ngFor="let new of category.items"
        [class]="'card-bottom'">
        <div
          [id]="new.getId()"
          (click)="open(new.getId())"  >
          <mat-card-header>
            <div mat-card-avatar class="img"
              [ngStyle]="{'background-image': 'url(' + new.getImage() + ')'}" >
            </div>
            <mat-card-title> {{ new.getTitle() }}</mat-card-title>
            <mat-card-subtitle>{{ new.getShortdescription() }}</mat-card-subtitle>
            <mat-card-subtitle>
              <div class="footer">
                <div class="date">{{ new.getPublishdate() | amParse: 'YYYYMMDD' | amDateFormat: dateFormat}}</div>
                <div *ngIf="new.getYoutube() != ''" (click)="stop($event)">
                  <a href="{{new.getYoutube()}}">
                  <img class="youtube-logo" src="assets/images/youtube-logo.png">
                </a>
              </div>
            </div>          
            </mat-card-subtitle>
          </mat-card-header>
        </div>
      </mat-card>

    </mat-expansion-panel>

  </mat-accordion>

</div>

<ng-template #loading>
  <div class="form-wait-bg"></div>
  <div class="form-wait">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

</app-sidenav>