import { Component, OnInit, OnDestroy } from '@angular/core';
import { Member, Title, PoloSize, Country } from 'src/app/libs/proto/commUnity_pb';
import { Subscription } from 'rxjs';
import { GrpcMemberLibService } from 'src/app/service/grpc/member/grpc-member-lib.service';
import { TranslateService } from '@ngx-translate/core';
import { UserLibService } from 'src/app/service/user/user-lib.service';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { GrpcUserService } from 'src/app/service/grpc/user/grpc-user.service';
import { desktopMode } from '../../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.sass']
})
export class MemberComponent implements OnInit, OnDestroy {
  member: Member;
  private actRouter$: Subscription;
  title = '';

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;

  menuType = 0;


  constructor(
    private grpcMemberLib: GrpcMemberLibService,
    private translate: TranslateService,
    private userLib: UserLibService,
    private actRoute: ActivatedRoute,
    private safurl: DomSanitizer,
    private detector: DeviceLibService,
  ) {
    userLib.setPageLanguage( this.translate );
  }

  textPolosize(v: number) {
    let usize = '';
    Object.keys(PoloSize).map( c => {
      if (PoloSize[c] === v) { usize = c; }
    });
    return usize;
  }

  textCountry(v: number) {
    let uctry = '';
    Object.keys(Country).map( c => {
      if (Country[c] === v) { uctry = c; }
    });
    return uctry;
  }

  ngOnInit(): void {
    const thise = this;
    this.actRouter$ = this.actRoute.paramMap.subscribe( p => {
      thise.grpcMemberLib.getMember({
        Offline: true,
        call: {
          req: p.get('id'),
        }
      }).then( ns => {
        thise.member = ns[0];
        Object.keys(Title).map( c => {
          if (Title[c] === this.member.getTitle()) { this.title = c; }
      });
      if (this.userLib.Data.memberPage === undefined) {
        this.userLib.Data.memberPage = {};
      }
      if (thise.member) { this.userLib.Data.memberPage.id = thise.member.getId(); }

    });

      this.isLandscape = this.detector.orientation === 'landscape';
      this.menuType = this.getmenuType();
      this.landscapeEvt.addEventListener('change', ev => {
        this.isLandscape = this.landscapeEvt.matches;
        this.menuType = this.getmenuType();
      });
    });
  }
  get memberSetting() {
    return this.userLib.Data.memberSettings;
  }

  get photo_suffix() {
    return '?ts=' + moment().unix();
  }

  ngOnDestroy(): void {
    this.actRouter$.unsubscribe();
  }
  get backgroundImage() {
    return this.userLib.Data.token?.getCustomer() && this.userLib.Data.token?.getCustomer().getBackgroundprofile();
  }
  get memberName() {
    const p = this.member;
    return [
      p && p.getFirstname() || '',
      p && p.getLastname() || ''
    ].join(' ');
  }
  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }
  get foreColor() {
    return this.userLib.designToolbarTextColor;
  }

  tellink(tel: string) {
    return this.safurl.bypassSecurityTrustUrl(tel);
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer()?.getMobilemenutype();
  }
}
