<div class="bg-img" [ngStyle]="{'background-image': 'url(' + (backgroundImage | pipeWebp) +')','background-size': backgroundSize}"></div>
<div class="main-content">

  <div class="company-logo" [ngClass]="{'with-banner': show_banner_homepage}">
    <div class="logo" [ngStyle]="{'background-image': 'url(' + (logo | pipeWebp) +')'}"></div>
    <h1 [ngStyle]="{'color': textColor }">{{ customerName }}</h1>
    <button *ngIf="show_flag_homepage" class="flags" mat-icon-button [matMenuTriggerFor]="menu" aria-label="flags">
      <img class="flag" src="/assets/flags/{{ userlang }}.png" />
    </button>
    <mat-menu class="lang-menu" #menu="matMenu" yPosition="below">
      <button (click)="change_lang(l)" mat-menu-item *ngFor="let l of LangFromGRPC">
        <img class="flag" src="/assets/flags/{{ l }}.png" />
      </button>
    </mat-menu>

    <div
    (click)="toGroup()"
    *ngIf="groupLogo"
    class="group-logo">
    <div class="glogo"
      [ngStyle]="{'background-image': 'url(' + (groupLogo | pipeWebp) +')'}"></div>
    </div>
  </div>

  <ng-image-slider #bannerslide *ngIf="show_banner_homepage"
  class="img-slider banner-container"
  [autoSlide]="true"
  [infinite]="true"
  [imageSize]="bannerimageSize"
  [manageImageRatio]="true"
  (imageClick)="open_banner($event)"
  [images]="bannerimageObject">
  </ng-image-slider>

  <div class="company-search mat-subheading-1" *ngIf="hasSearch">
    <div class="search" [ngStyle]="{'border-bottom': 'solid 1px ' + textColor}">
      <a [routerLink]="[ '/search' ]" routerLinkActive="active">
      <span [ngStyle]="{'color': textColor }">{{ 'search.title' | translate}}</span>
      <mat-icon [ngStyle]="{'color': textColor}" aria-hidden="false" aria-label="back">search</mat-icon>
      </a>
    </div>
  </div>

  <div *ngIf="menuType===0" 
    [ngClass]="{
    'has-search': hasSearch,
    'has-banner': show_banner_homepage}" class="menus" >    
    <app-menu-type-0 ></app-menu-type-0>
  </div>

  <div *ngIf="menuType===1" 
    [ngClass]="{
      'has-search': hasSearch,
      'has-banner': show_banner_homepage
    }" class="menus-photo mat-subheading-1">
    <app-menu-type-1 ></app-menu-type-1>
  </div>

  <div *ngIf="menuType===2" [ngClass]="{
    'not4': menucount < 4,
    'has-search': hasSearch,
    'has-banner': show_banner_homepage}" class="menus-tablet mat-subheading-1">    
    <app-menu-type-2 ></app-menu-type-2>
  </div>

  <div style="display: flex;justify-content: space-between;" class="bottom mat-subheading-1" [ngStyle]="{'background-color': backgroundColor || 'white'}">
    <div class="btn-account" *ngIf="showAccount">
      <button mat-button [ngStyle]="{'color': textColor}" (click)="account()">
        <mat-icon [ngStyle]="{'color': textColor}" aria-hidden="false" aria-label="back">person</mat-icon>
        <span>{{ 'home.My_account' | translate }}</span>
      </button>
    </div>
    <div
      *ngIf="hasAlerts"
      class="btn-alert">
      <button
        (click)="doAlert()"
        mat-flat-button [color]="'warn'">
        {{ 'alert.button' | translate }}
      </button>
    </div>
    <div class="btn-disconnect">
      <button mat-button [ngStyle]="{'color': textColor}" class="btn-disconnect" (click)="rightButton()">
        <mat-icon [ngStyle]="{'color': textColor}" class="exit" aria-hidden="false" aria-label="back">arrow_circle_down</mat-icon>
        <span>{{ (isGuest ? 'home.signin_button':'home.Disconnect') | translate }}</span>
      </button>
    </div>
  </div>

</div>

<app-alert
  (evt)="closeAlert()"
  *ngIf="showAlert"></app-alert>

<app-ads
  (evt)="closeAds()"
  [Ad]="showAd"
  *ngIf="showAd">
</app-ads>

<!--
<a href="https://www.flaticon.com/free-icons/portugal" title="portugal icons">Portugal icons created by rimsha-ibrar - Flaticon</a>
<a href="https://www.flaticon.com/free-icons/holland" title="holland icons">Holland icons created by Roundicons - Flaticon</a>
-->