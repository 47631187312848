<app-sidenav page="member-page" menuType>

<div class="form-list" *ngIf="menuType!=2">

  <mat-card class="p-0">
    <div
      class="top-image"
      [ngStyle]="{'background-image': 'url('+ (backgroundImage | pipeWebp) +')'}">
    </div>

    <a class="top-tool top-call" (click)="$event.stopPropagation()" [href]="tellink('tel:' + (member?.getProfessionaladdress()?.getPhone() || member?.getProfessionaladdress()?.getMobile()))"><mat-icon class="icon">
      call
    </mat-icon></a>

    <div
      [ngStyle]="{'background-image': 'url(' + (member?.getPhoto() | pipeWebp) + photo_suffix + ')'}"
      class="top-avatar">
    </div>

    <a class="top-tool top-mail" (click)="$event.stopPropagation()" href="mailto:{{ (member?.getProfessionaladdress()?.getEmail() || member?.getPersonaladdress()?.getEmail()) }}"><mat-icon class="icon-right">
      mail_outline
    </mat-icon></a>

    <mat-card-content>

      <mat-list class="member">

        <mat-list-item class="MemberName">
          <h2 class="name">{{ memberName }}</h2>
        </mat-list-item>

        <mat-list-item *ngIf="memberSetting?.getShowtitle()" class="item">
          <div class="title">
            {{'member.title' | translate }}
          </div>
          <div class="detail">
            {{ ('title.' + title) | translate }}
          </div>
        </mat-list-item>
        <mat-list-item *ngIf="memberSetting?.getShowfunction()" class="item">
          <div class="title">
            {{'member.function' | translate }}
          </div>
          <div class="detail">
            {{member?.getFunction()}}
          </div>
        </mat-list-item>
        <mat-list-item *ngIf="memberSetting?.getShowactivitysector()" class="item">
          <div class="title">
            {{'member.activitySector' | translate }}
          </div>
          <div class="detail">
            {{member?.getActivitysector()}}
          </div>
        </mat-list-item>
        <mat-list-item *ngIf="memberSetting?.getShowdescription()" class="item">
          <div class="title">
            {{'member.description' | translate }}
          </div>
          <div class="detail">
            {{member?.getDescription()}}
          </div>
        </mat-list-item>

        <mat-list-item *ngIf="memberSetting?.getShowshortdescription()" class="item">
          <div class="title">
            {{'member.shortdescription' | translate }}
          </div>
          <div class="detail">
            {{member?.getShortdescription()}}
          </div>
        </mat-list-item>

        <ng-container
        *ngIf="memberSetting?.getShowprofessionaladdress() && member?.hasProfessionaladdress()"
        [ngTemplateOutlet]="address"
        [ngTemplateOutletContext]="{
          address: member?.getProfessionaladdress(),
          setting: memberSetting,
          title: 'member.ContactInfo',
          showmobile: memberSetting?.getShowprofessionaladdressmobile(),
          showphone: memberSetting?.getShowprofessionaladdressphone(),
          showemail: memberSetting?.getShowprofessionaladdressemail(),
          showcountry: memberSetting?.getShowcountry()}">
        </ng-container>

        <ng-container
        *ngIf="memberSetting?.getShowpersonaladdress() && member?.hasPersonaladdress()"
        [ngTemplateOutlet]="address"
        [ngTemplateOutletContext]="{
          address: member?.getPersonaladdress(),
          setting: memberSetting,
          title: 'member.PersonInfo',
          showmobile: memberSetting?.getShowpersonaladdressmobile(),
          showphone: memberSetting?.getShowpersonaladdressphone(),
          showemail: memberSetting?.getShowpersonaladdressemail(),
          showcountry: memberSetting?.getShowcountry()}">
        </ng-container>

        <mat-list-item *ngIf="memberSetting?.getShowbirthday()" class="item">
          <div class="title">
            {{'member.birthday' | translate }}
          </div>
          <div class="detail">
            {{member?.getBirthday()| pipeDatefmt}}
          </div>
        </mat-list-item>

        <ng-container
        *ngIf="memberSetting?.getShowsocialnetwork()"
        [ngTemplateOutlet]="social"
        [ngTemplateOutletContext]="{social: member?.getSocialnetwork(), setting: memberSetting}">
        </ng-container>

        <mat-list-item *ngIf="memberSetting?.getShowcompany()" class="item">
          <div class="title">
            {{'member.company' | translate }}
          </div>
          <div class="detail">
            {{member?.getCompany()}}
          </div>
        </mat-list-item>

        <mat-list-item *ngIf="memberSetting?.getShowurl()" class="item">
          <div class="title">
            {{'member.URL' | translate }}
          </div>
          <div class="detail">
            {{member?.getUrl()}}
          </div>
        </mat-list-item>

        <mat-list-item *ngIf="memberSetting?.getShowinterest()" class="item">
          <div class="title">
            {{'member.interest' | translate }}
          </div>
          <div class="detail">
            {{member?.getInterestList().join(', ')}}
          </div>
        </mat-list-item>

        <mat-list-item *ngIf="memberSetting?.getShowindex()" class="item">
          <div class="title">
            {{'member.index' | translate }}
          </div>
          <div class="detail">
            {{member?.getIndex()}}
          </div>
        </mat-list-item>

        <mat-list-item *ngIf="memberSetting?.getShowlicense()" class="item">
          <div class="title">
            {{'member.license' | translate }}
          </div>
          <div class="detail">
            {{member?.getLicense()}}
          </div>
        </mat-list-item>

        <mat-list-item *ngIf="memberSetting?.getShowmembersince()" class="item">
          <div class="title">
            {{'member.memberSince' | translate }}
          </div>
          <div class="detail">
            {{member?.getMembersince()| pipeDatefmt}}
          </div>
        </mat-list-item>

        <mat-list-item *ngIf="memberSetting?.getShowrenewon()" class="item">
          <div class="title">
            {{'member.renewOn' | translate }}
          </div>
          <div class="detail">
            {{member?.getRenewon()| pipeDatefmt}}
          </div>
        </mat-list-item>

        <mat-list-item *ngIf="memberSetting?.getShowpolosize()" class="item">
          <div class="title">
            {{'member.poloSize' | translate }}
          </div>
          <div class="detail">
            {{ textPolosize(member?.getPolosize()) }}
          </div>
        </mat-list-item>

        <mat-list-item *ngIf="memberSetting?.getShowprivate()" class="item">
          <div class="title">
            {{'member.private' | translate }}
          </div>
          <div class="detail">
            {{ (member?.getPrivate() ? 'Dialog.Yes' : 'Dialog.No') | translate }}
          </div>
        </mat-list-item>

      </mat-list>

    </mat-card-content>
  </mat-card>
</div>

<ng-template #address
  let-address="address"
  let-setting="setting"
  let-title="title"
  let-showphone="showphone"
  let-showmobile="showmobile"
  let-showcountry="showcountry"
  let-showemail="showemail" >
  <mat-list-item
    [ngStyle]="{'background-color': backgroundColor,
                color: foreColor}"
    class="group">
    <span>{{ title | translate }}</span>
  </mat-list-item>

  <mat-list-item *ngIf="address?.getStreet()" class="item">
    <div class="title">
      {{'member.street' | translate }}
    </div>
    <div class="detail">
      {{address?.getStreet()}}
    </div>
  </mat-list-item>

  <mat-list-item *ngIf="address?.getStreet2()" class="item">
    <div class="title">
      {{'member.street2' | translate }}
    </div>
    <div class="detail">
      {{address?.getStreet2()}}
    </div>
  </mat-list-item>

  <mat-list-item *ngIf="address?.getCity()" class="item">
    <div class="title">
      {{'member.city' | translate }}
    </div>
    <div class="detail">
      {{address?.getCity()}}
    </div>
  </mat-list-item>

  <mat-list-item *ngIf="address?.getPostcode()" class="item">
    <div class="title">
      {{'member.postCode' | translate }}
    </div>
    <div class="detail">
      {{address?.getPostcode()}}
    </div>
  </mat-list-item>

  <mat-list-item *ngIf="showcountry" class="item">
    <div class="title">
      {{'member.Country' | translate }}
    </div>
    <div class="detail">
      {{ textCountry(address?.getCountry()) }}
    </div>
  </mat-list-item>

  <mat-list-item *ngIf="address?.getPhone() && showphone" class="item">
    <div class="title">
      {{'member.phone' | translate }}
    </div>
    <div class="detail">
      {{address?.getPhone()}}
    </div>
  </mat-list-item>

  <mat-list-item *ngIf="address?.getMobile() && showmobile" class="item">
    <div class="title">
      {{'member.mobile' | translate }}
    </div>
    <div class="detail">
      {{address?.getMobile()}}
    </div>
  </mat-list-item>

  <mat-list-item *ngIf="address?.getEmail() && showemail" class="item">
    <div class="title">
      {{'member.email' | translate }}
    </div>
    <div class="detail">
      {{address?.getEmail()}}
    </div>
  </mat-list-item>
</ng-template>

<ng-template #social let-social="social" let-setting="setting" >
  <mat-list-item
    [ngStyle]="{'background-color': backgroundColor,
                color: foreColor}"
    class="group">
    <span>{{ 'SocialNetwork' | translate }}</span>
  </mat-list-item>

  <mat-list-item class="item" *ngIf="social?.getFacebook()">
  <div class="title">
    {{'member.facebook' | translate  }}
  </div>
  <div class="detail">
    {{social?.getFacebook()}}
  </div>
</mat-list-item>
<mat-list-item class="item" *ngIf="social?.getTwitter()">
  <div class="title">
    {{'member.twitter' | translate }}
  </div>
  <div class="detail">
    {{social?.getTwitter()}}
  </div>
</mat-list-item>
<mat-list-item class="item" *ngIf="social?.getLinkedin()">
  <div class="title">
    {{'member.telegram' | translate }}
  </div>
  <div class="detail">
    {{social?.getTelegram() }}
  </div>
</mat-list-item>

<mat-list-item class="item" *ngIf="social?.getLinkedin()">
  <div class="title">
    {{'member.linkedin' | translate }}
  </div>
  <div class="detail">
    {{social?.getLinkedin() }}
  </div>
</mat-list-item>
<mat-list-item class="item" *ngIf="social?.getInstagram()">
  <div class="title">
    {{'member.instagram' | translate }}
  </div>
  <div class="detail">
    {{social?.getInstagram()}}
  </div>
</mat-list-item>
<mat-list-item class="item" *ngIf="social?.getSnapshat()">
  <div class="title">
    {{'member.snapchat' | translate }}
  </div>
  <div class="detail">
    {{social?.getSnapshat()}}
  </div>
</mat-list-item>
<mat-list-item class="item" *ngIf="social?.getSkype()">
  <div class="title">
    {{'member.skype' | translate }}
  </div>
  <div class="detail">
    {{social?.getSkype()}}
  </div>
</mat-list-item>

<mat-list-item class="item" *ngIf="social?.getWhatsapp()">
  <div class="title">
    {{'member.whatsapp' | translate }}
  </div>
  <div class="detail">
    {{social?.getWhatsapp()}}
  </div>
</mat-list-item>
<mat-list-item class="item" *ngIf="social?.getPinterest()">
  <div class="title">
    {{'member.pinterest' | translate }}
  </div>
  <div class="detail">
    {{social?.getPinterest()}}
  </div>
</mat-list-item>

</ng-template>

<div class="tablet-mode" *ngIf="menuType==2">
  <div class="left">
    <mat-card class="p-0">
      <div class="top-image" [ngStyle]="{'background-image': 'url('+ (backgroundImage | pipeWebp) +')'}">
      </div>
  
      <a class="top-tool top-call" (click)="$event.stopPropagation()"
        [href]="tellink('tel:' + (member?.getProfessionaladdress()?.getPhone() || member?.getProfessionaladdress()?.getMobile()))">
        <mat-icon class="icon">
          call
        </mat-icon>
      </a>
  
      <div [ngStyle]="{'background-image': 'url(' + (member?.getPhoto() | pipeWebp) + photo_suffix + ')'}"
        class="top-avatar">
      </div>
  
      <a class="top-tool top-mail" (click)="$event.stopPropagation()"
        href="mailto:{{ (member?.getProfessionaladdress()?.getEmail() || member?.getPersonaladdress()?.getEmail()) }}">
        <mat-icon class="icon-right">
          mail_outline
        </mat-icon>
      </a>
    </mat-card>
  </div>

  <div class="right">
    <mat-card class="p-0">
  
      <mat-card-content>
  
        <mat-list class="member">
  
          <mat-list-item class="MemberName">
            <h2 class="name">{{ memberName }}</h2>
          </mat-list-item>
  
          <mat-list-item *ngIf="memberSetting?.getShowtitle()" class="item">
            <div class="title">
              {{'member.title' | translate }}
            </div>
            <div class="detail">
              {{ ('title.' + title) | translate }}
            </div>
          </mat-list-item>
          <mat-list-item *ngIf="memberSetting?.getShowfunction()" class="item">
            <div class="title">
              {{'member.function' | translate }}
            </div>
            <div class="detail">
              {{member?.getFunction()}}
            </div>
          </mat-list-item>
          <mat-list-item *ngIf="memberSetting?.getShowactivitysector()" class="item">
            <div class="title">
              {{'member.activitySector' | translate }}
            </div>
            <div class="detail">
              {{member?.getActivitysector()}}
            </div>
          </mat-list-item>
          <mat-list-item *ngIf="memberSetting?.getShowdescription()" class="item">
            <div class="title">
              {{'member.description' | translate }}
            </div>
            <div class="detail">
              {{member?.getDescription()}}
            </div>
          </mat-list-item>
  
          <mat-list-item *ngIf="memberSetting?.getShowshortdescription()" class="item">
            <div class="title">
              {{'member.shortdescription' | translate }}
            </div>
            <div class="detail">
              {{member?.getShortdescription()}}
            </div>
          </mat-list-item>
  
          <ng-container *ngIf="memberSetting?.getShowprofessionaladdress() && member?.hasProfessionaladdress()"
            [ngTemplateOutlet]="address" [ngTemplateOutletContext]="{
            address: member?.getProfessionaladdress(),
            setting: memberSetting,
            title: 'member.ContactInfo',
            showmobile: memberSetting?.getShowprofessionaladdressmobile(),
            showphone: memberSetting?.getShowprofessionaladdressphone(),
            showemail: memberSetting?.getShowprofessionaladdressemail(),
            showcountry: memberSetting?.getShowcountry()}">
          </ng-container>
  
          <ng-container *ngIf="memberSetting?.getShowpersonaladdress() && member?.hasPersonaladdress()"
            [ngTemplateOutlet]="address" [ngTemplateOutletContext]="{
            address: member?.getPersonaladdress(),
            setting: memberSetting,
            title: 'member.PersonInfo',
            showmobile: memberSetting?.getShowpersonaladdressmobile(),
            showphone: memberSetting?.getShowpersonaladdressphone(),
            showemail: memberSetting?.getShowpersonaladdressemail(),
            showcountry: memberSetting?.getShowcountry()}">
          </ng-container>
  
          <mat-list-item *ngIf="memberSetting?.getShowbirthday()" class="item">
            <div class="title">
              {{'member.birthday' | translate }}
            </div>
            <div class="detail">
              {{member?.getBirthday()| pipeDatefmt}}
            </div>
          </mat-list-item>
  
          <ng-container *ngIf="memberSetting?.getShowsocialnetwork()" [ngTemplateOutlet]="social"
            [ngTemplateOutletContext]="{social: member?.getSocialnetwork(), setting: memberSetting}">
          </ng-container>
  
          <mat-list-item *ngIf="memberSetting?.getShowcompany()" class="item">
            <div class="title">
              {{'member.company' | translate }}
            </div>
            <div class="detail">
              {{member?.getCompany()}}
            </div>
          </mat-list-item>
  
          <mat-list-item *ngIf="memberSetting?.getShowurl()" class="item">
            <div class="title">
              {{'member.URL' | translate }}
            </div>
            <div class="detail">
              {{member?.getUrl()}}
            </div>
          </mat-list-item>
  
          <mat-list-item *ngIf="memberSetting?.getShowinterest()" class="item">
            <div class="title">
              {{'member.interest' | translate }}
            </div>
            <div class="detail">
              {{member?.getInterestList().join(', ')}}
            </div>
          </mat-list-item>
  
          <mat-list-item *ngIf="memberSetting?.getShowindex()" class="item">
            <div class="title">
              {{'member.index' | translate }}
            </div>
            <div class="detail">
              {{member?.getIndex()}}
            </div>
          </mat-list-item>
  
          <mat-list-item *ngIf="memberSetting?.getShowlicense()" class="item">
            <div class="title">
              {{'member.license' | translate }}
            </div>
            <div class="detail">
              {{member?.getLicense()}}
            </div>
          </mat-list-item>
  
          <mat-list-item *ngIf="memberSetting?.getShowmembersince()" class="item">
            <div class="title">
              {{'member.memberSince' | translate }}
            </div>
            <div class="detail">
              {{member?.getMembersince()| pipeDatefmt}}
            </div>
          </mat-list-item>
  
          <mat-list-item *ngIf="memberSetting?.getShowrenewon()" class="item">
            <div class="title">
              {{'member.renewOn' | translate }}
            </div>
            <div class="detail">
              {{member?.getRenewon()| pipeDatefmt}}
            </div>
          </mat-list-item>
  
          <mat-list-item *ngIf="memberSetting?.getShowpolosize()" class="item">
            <div class="title">
              {{'member.poloSize' | translate }}
            </div>
            <div class="detail">
              {{ textPolosize(member?.getPolosize()) }}
            </div>
          </mat-list-item>
  
          <mat-list-item *ngIf="memberSetting?.getShowprivate()" class="item">
            <div class="title">
              {{'member.private' | translate }}
            </div>
            <div class="detail">
              {{ (member?.getPrivate() ? 'Dialog.Yes' : 'Dialog.No') | translate }}
            </div>
          </mat-list-item>
  
        </mat-list>
  
      </mat-card-content>
    </mat-card>
  </div>
  
</div>

</app-sidenav>