import { Component, OnInit, Sanitizer } from '@angular/core';
import { Router } from '@angular/router';
import { UserLibService } from 'src/app/service/user/user-lib.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageLibService } from 'src/app/service/storage/storage-lib.service';
import { GrpcMemberLibService } from 'src/app/service/grpc/member/grpc-member-lib.service';
import { Member } from 'src/app/libs/proto/commUnity_pb';
import { GrpcLibService } from 'src/app/service/grpc/grpc-lib.service';
import { of } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { GrpcUserService } from 'src/app/service/grpc/user/grpc-user.service';
import { desktopMode } from '../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.sass']
})
export class MembersComponent implements OnInit {
  members$: Promise<Member[]>;
  user: Member;

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;

  menuType = 0;
  onceScroll = false;
  defaultCollapse: string;

  constructor(
    private route: Router,
    private grpcMemberLib: GrpcMemberLibService,
    private userLib: UserLibService,
    private translate: TranslateService,
    private storeLib: StorageLibService,
    private grpUsercLib: GrpcUserService,
    private sfurl: DomSanitizer,
    private detector: DeviceLibService,
  ) {
    userLib.setPageLanguage( this.translate );
    this.user = this.userLib.Data.token?.getProfile();
  }
  get memberSetting() {
    return this.userLib.Data.memberSettings;
  }
  ngOnInit(): void {

    this.members$ = this.grpcMemberLib.getMember({
      Offline: this.storeLib.cache.member || false,
    }).catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        this.userLib.clear();
        this.route.navigateByUrl('/login');
        return;
      }
      // return to complete
      return of([]).toPromise();
    });

    if (this.userLib.Data.memberSettings === undefined) {
      this.grpUsercLib.getMemberSettings().then( s => {
        this.userLib.Data.memberSettings = s;
      });
    }

    this.defaultCollapse = this.userLib.Data.token?.getCustomer().getDefaultcollapsemember() ? 'y':'n'

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
      this.isLandscape = this.landscapeEvt.matches;
      this.menuType = this.getmenuType();
    });
  }
  open(id: string){
    this.route.navigate(['/member', id]);
  }

  tellink(tel: string) {
    return this.sfurl.bypassSecurityTrustUrl(tel);
  }

  hasphone(member: Member) {
    return this.getphone(member) !== '';
  }
  getphone(member: Member) {
    if (member == null) { return ''; }

    if (member?.hasProfessionaladdress() ) {
      if (this.memberSetting?.getShowprofessionaladdressphone() && member?.getProfessionaladdress()?.getPhone()) {
        return member?.getProfessionaladdress()?.getPhone();
      }else  if (this.memberSetting?.getShowprofessionaladdressmobile() && member?.getProfessionaladdress()?.getMobile()) {
        return member?.getProfessionaladdress()?.getMobile();
      }
    } else if (member?.hasPersonaladdress()){
      if (this.memberSetting?.getShowpersonaladdressphone() && member?.getPersonaladdress()?.getPhone()) {
        return member?.getPersonaladdress()?.getPhone();
      }else  if (this.memberSetting?.getShowpersonaladdressmobile() && member?.getPersonaladdress()?.getMobile()) {
        return member?.getPersonaladdress()?.getMobile();
      }
    }
    return '';
  }

  hasemail(member: Member) {
    return this.getemail(member) !== '';
  }
  getemail(member: Member) {
    if (member == null) { return ''; }

    if (member?.hasProfessionaladdress() ) {
      if (this.memberSetting?.getShowprofessionaladdressemail() && member?.getProfessionaladdress()?.getEmail()) {
        return member?.getProfessionaladdress()?.getEmail();
      }
    } else if (member?.hasPersonaladdress()){
      if (this.memberSetting?.getShowpersonaladdressemail() && member?.getPersonaladdress()?.getEmail()) {
        return member?.getPersonaladdress()?.getEmail();
      }
    }
    return '';
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer()?.getMobilemenutype();
  }

  get hasCategory() {
    return this.userLib.Data.token?.getCustomer()?.getHasmembercategory() || false;
  }
  clearScrollFlag() {
    if (!this.hasCategory) return;

    this.onceScroll = true;
  }

  expanded(id: string, t: number){
    if (t !== 2) { this.onceScroll = true; }
    if (this.userLib.Data.memberPage === undefined) {
      this.userLib.Data.memberPage = {};
    }
    if (this.userLib.Data.memberPage.collapsed === undefined ){
      this.userLib.Data.memberPage.collapsed = [];
    }
    const ii = this.userLib.Data.memberPage?.collapsed.indexOf(id);
    if (t === 1) {
       this.userLib.Data.memberPage?.collapsed.splice(ii, 1);
    } else {
      if (ii === -1) {
        this.userLib.Data.memberPage?.collapsed.push(
        id
        );
      }
    }
  }
  isCollapsed(c: string) {
    if (this.defaultCollapse) { return this.defaultCollapse === 'y'; }
    return this.userLib.Data.memberPage?.collapsed?.indexOf(c) >= 0;
  }

}
